/// <reference path="./importer.ts" />

import {Cart} from "./partial/cart";

export class Header {

    private static LAST_COUNTRY_KEY: string = 'last-country';
    private cart: any = null;

    public constructor() {
        this.initialize();
    }

    public getCart(): any {
        return this.cart;
    }

    private initialize(): void {
        this.initializeHamburgerMenu();
        this.initializeLanguage();
        this.intializeCart();
        this.bindEvents();
        this.initializeSmoothScrolling();
    }

    private bindEvents(): void {
        $('.site-navigation .countries .country').on('click', (e) => {
            let countryCode = $(e.target).closest('.country').data('code');

            this.setCountryVisualization(countryCode);
            this.cart.setCountry(countryCode);
        });

        this.cart.onCartLoaded(() => {
            let cartData = this.cart.getCartData();
            this.setCountryVisualization(cartData.country_iso);
        }, true, true);
    }

    private initializeSmoothScrolling(): void {
        $('a[href*=\\#]').on('click', function (event) {
            let ourPosition = $(this).offset().top;
            let offset = $(this.hash).offsetParent().offset().top - 80;
            let scrollSpeed = Math.abs(offset - ourPosition) / 3;
            scrollSpeed = scrollSpeed < 500 ? 500 : scrollSpeed;
            $('html,body').animate({scrollTop: offset}, scrollSpeed);
        });
    }

    private intializeCart(): void {
        this.cart = new Cart();
        this.cart.initialize();
    }

    private initializeLanguage(): void {
        let localCountry = localStorage.getItem(Header.LAST_COUNTRY_KEY);
        if (localCountry) {
            this.setCountryVisualization(localCountry);
        }
    }

    private initializeHamburgerMenu(): void {
        /*
        Hamburger button gets most likely manipulated in the dom
        so $('.hamburger-toggle').click() doesn't work
         */
        $('body').on('click', '.hamburger-toggle', () => {
            $('.hamburger-menu').toggleClass('open');
        });
    }

    private setCountryVisualization(countryCode: string): void {
        let countryHtml = $('.site-navigation .countries .country[data-code="' + countryCode + '"]').html();
        if (countryHtml && countryHtml.length > 0) {
            $('.site-navigation .countries .active').html(countryHtml);
            localStorage.setItem(Header.LAST_COUNTRY_KEY, countryCode);
        }
    }
}

$(document).ready(() => {
    //All pages should have a header and the cart functionality
    const header = new Header();
    //We'll put the header (that contains the cart) on the global scope, so that it is
    //accessible for other pages
    // (An alternative would be to make a singleton Application class)
    (<any>window).header = header;
});