/// <reference path="../importer.ts" />

//noinspection ES6ConvertVarToLetConst
declare const Vue : any;

interface ICartContext {
    managementUrl: string;
    rentalKey: string;
    language: string;
    cartProductTranslations: { [key: string]: string };
    paymentSuccessUrl: string;
    paymentFailureUrl: string;
    paymentReturnUrl: string;
    managementBaseUrl: string;
    currencies: any;
    configurationsUrl: string;
}

//For documentation on IPaymentFormData, see management
interface IPaymentFormData {
    order: any;
    rentalKey: string;
    successUrl?: string;
    failureUrl?: string;
    redirectUrl?: string;
    returnUrl ?: string;
    managementUrlBase?: string;
    managementPaymentMethodUrl?: string;
    managementPaymentUrl?: string;
    successFunction ?: (data: any) => void;
    failureFunction ?: (err: any) => void;
}

declare const cartContext: ICartContext, require, dataLayer;

declare class TwikitPaymentForm {
    getForm(data: IPaymentFormData, callback): void;
}

interface IPrice {
    exclusive: number;
    inclusive: number;
    vat: number;
    vat_percentage: number;
}

//noinspection ES6ConvertVarToLetConst
declare let twikitPaymentForm;

export class Cart {
    private apiUrl: string = '';
    private configurationsUrl: string = '';
    private apiSecret: string = '';
    private language: string = '';
    private vue = null;
    private loadCompleteCallbacks = [];
    private reloadCompleteCallbacks = [];
    private vueData: any;

    public constructor() {
        this.apiUrl = cartContext.managementUrl;
        this.apiSecret = cartContext.rentalKey;
        this.language = cartContext.language;
        this.configurationsUrl = cartContext.configurationsUrl;
    }

    private isInitialized(): boolean {
        return this.vue && this.vueData && this.vueData.loaded;
    }

    private hasLoadedCallbacks(): boolean {
        return this.loadCompleteCallbacks && this.loadCompleteCallbacks.length > 0;
    }

    /**
     * This method will notify listeners on cart loaded
     */
    private cartLoaded(): void {
        this.vueData.loaded = true;
        if (this.hasLoadedCallbacks()) {
            this.loadCompleteCallbacks.forEach((callbackFunction: () => void) => {
                callbackFunction();
            });
        }
    }

    /**
     * Constructs the payment options form
     * where the user can choose an option method and proceed
     * The form itself is created by using a management JavaScript 'plugin'
     */
    private initializePaymentUI() : void {
        if (!twikitPaymentForm) {
            twikitPaymentForm = new TwikitPaymentForm(); //Be mindful that this won't actually work since the plugin is browserified
            //and that the class is not present on the global scope (unless it is typescript imported)
        }
        $('.popup-container.payment .buttons .checkout-proceed').remove();
        $(".popup-container.payment #checkout-form").empty();
        $('.popup-container.payment').removeClass('payment-loaded');
        twikitPaymentForm.getForm({
            rentalKey: cartContext.rentalKey,
            order: this.vueData.reviewModel,
            successUrl: this.vueData.baseUrl + cartContext.paymentSuccessUrl,
            failureUrl: this.vueData.baseUrl + cartContext.paymentFailureUrl,
            returnUrl: this.vueData.baseUrl + cartContext.paymentReturnUrl,
            managementUrlBase: cartContext.managementBaseUrl
        }, (err : any, html) => {
            if (err) {
                console.log("an error occured, ... whoops", err);
                this.blockUi(false);
            }
            $('.popup-container.payment').addClass('payment-loaded');
            $(".popup-container.payment #checkout-form").append(html);
            let proceedButton = $(".popup-container.payment #checkout-form .proceed-button");
            proceedButton.on('click', () => {
                //vue.setMode('payment-external');
                this.blockUi(true);
            });
            proceedButton.addClass('checkout-proceed button');
            proceedButton.html($('.popup-container.payment .proceed-button-content').html());
            proceedButton.prependTo($('.popup-container.payment .buttons'));
        });
    }

    /**
     * Shows the loader
     * Caution: When loader is shown the user cannot perform anything
     * in the user interface anymore
     * @param {boolean} isBlocked
     */
    private blockUi(isBlocked: boolean): void {
        $(".blocker").toggleClass('active', isBlocked);
    }

    /**
     * Translates something based on zone and key
     * @param {string} zone
     * @param {string} key
     * @returns {string}
     */
    private translate(zone: string, key: string): string {
        if (cartContext.cartProductTranslations
            && cartContext.cartProductTranslations[zone]
            && cartContext.cartProductTranslations[zone][key])
            return cartContext.cartProductTranslations[zone][key];
        else
            return String('');
    }

    /**
     * You MUST call this method before using the cart. This
     * method will set the vue Data and initialize the vuejs instance
     */
    public initialize() {
        if (this.isInitialized())   //You shouldn't be initializing twice!
            return;

        $('body').addClass('cart-initialized');

        this.vueData = {
            cart: {
                items: {data: []},
                subtotal: {inclusive: 0, exclusive: 0},
                shipping_fee: {inclusive: 0, exclusive: 0},
                discount: {inclusive: 0, exclusive: 0},
                quantity_discount: {inclusive: 0, exclusive: 0},
                total: {inclusive: 0, exclusive: 0, vat: 0},
                country_iso: '',
                currency_iso: '',
                quantity_discount_per_config: [],
                voucher_code: ''
            },
            loaded: false,
            active: false,
            updating: false,
            mode: 'browse',
            checkoutModel: {
                billingAddress: {
                    business_address: false,
                    country_iso: ''
                },
                deliveryAddress: {
                    business_address: false,
                    country_iso: ''
                },
                orderId: '',
                has_errors: false,
                billingDiffersFromDelivery: false,
                terms_accepted: true
            },
            reviewModel: {
                billingAddress: {
                    business_address: false
                },
                deliveryAddress: {
                    business_address: false
                },
                orderId: '',
                configs: {data: []}
            },
            voucherValid: null,
            baseUrl: location.origin || (window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : ''))
        };

        let self = this;

        this.vue = new Vue({
            el: '#cart-vue',
            data: this.vueData,
            computed: {
                translatedCurrency: () => {
                    return cartContext.currencies[this.vueData.cart && this.vueData.cart.currency_iso];
                }
            },
            methods: {
                quantityDiscountForConfig: (configId: number) => {
                    return this.getQuantityDiscountForConfig(configId);
                },
                remove: (key: string) => {
                    this.removeItem(key);
                },
                checkout: () => {
                    this.checkout()
                },
                update: (item) => {
                    this.updateCart(item);
                },
                setMode: (mode: string) => {
                    this.setMode(mode);
                },
                addVoucher: (event: MouseEvent) => {
                    this.addVoucher();
                }
            },
            watch: {
                'cart.country_iso': (val) => {
                    this.vueData.checkoutModel.billingAddress.country_iso = val;
                    $('#billing-country').val(val).change();
                    //Do not change delivery as delivery as we only care if the user
                    //manually chooses other delivery from billing address
                },
                'checkoutModel.billingDiffersFromDelivery': (newVal: boolean, oldVal: boolean) => {
                    if (newVal == true && oldVal == false) {
                        //User just checked that the delivery address is different from billing address
                        this.vueData.checkoutModel.deliveryAddress.country_iso = this.vueData.checkoutModel.billingAddress.country_iso;
                        $('#delivery-country').val(this.vueData.checkoutModel.deliveryAddress.country_iso).change();
                    }
                    if (newVal == false && oldVal == true) {
                        this.vueData.checkoutModel.deliveryAddress.country_iso = '';
                    }
                }
            },
            filters: {
                translateProductTitle: (product) => {
                    const translationZone = 'Product: ' + product;
                    return this.translate(translationZone, 'Title');
                }
            }
        });

        //Start the show and actually fetch the cart from management
        this.getCart();

        $('.cart-summary').on('touchstart', function () {
            $(this).parents('.cart').addClass('tapped').toggleClass('active');
        });
    }

    private addVoucher(): void {
        //Keep the cart open
        $('.cart .cart-detail').addClass('opened');
        //Code should be in the vuejs instance
        let voucherCode: string = this.vueData.cart.voucher_code;
        if (!voucherCode) {
            //Code was not filled in , whatever
            this.vueData.voucherValid = null;
            return;
        }

        voucherCode = voucherCode.toUpperCase();
        this.blockUi(true);

        $.post(`${this.apiUrl}/cart/${window.localStorage.getItem('cartId')}/voucher/add?rental_secret=${this.apiSecret}`,
            {
                voucher_code: voucherCode
            })
            .then((cart) => {
                if (typeof cart === 'object') {
                    this.vueData.cart = cart;
                    this.cartLoaded();
                    this.vueData.voucherValid = (cart.voucher_code !== null);
                }
                else {
                    // should get cart
                    this.getCart();
                    this.vueData.voucherValid = false;
                }
            })
            .fail((err) => {
                this.vueData.voucherValid = false;
            })
            .always(() => {
                this.blockUi(false);
                setTimeout(() => {
                    $('.cart .cart-detail').removeClass('opened');
                }, 3000);
            });
    }

    private getQuantityDiscountForConfig(configId: number): IPrice {
        let quantityDiscountForConfig: any = null;
        if (this.vue && this.vueData.cart && this.vueData.cart.quantity_discount_per_config) {
            this.vueData.cart.quantity_discount_per_config.forEach((value) => {
                if (value.config_id == configId)
                    quantityDiscountForConfig = value;
            });
        }
        if (quantityDiscountForConfig)
            return quantityDiscountForConfig.unit_discount;
        else
            return {
                "exclusive": 0,
                "inclusive": 0,
                "vat": 0,
                "vat_percentage": 0
            };
    }

    private setMode(mode: string) {
        this.vueData.mode = mode;
        let modeStepOrder = ['checkout', 'review', 'payment', 'payment-external'];
        if (typeof  dataLayer != 'undefined') {
            dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {'step': modeStepOrder.indexOf(mode) + 1},
                        'products': this.vueData.cart.items.data.map((item) => {
                            return {
                                'id': item.key,
                                'price': item.price.inclusive.toFixed(2),
                                'quantity': item.amount
                            };
                        })
                    }
                }
            });
        }
    }

    public removeItem(key: string): void {
        this.vueData.updating = true;
        const itemToDelete = this.vueData.cart.items.data[key];
        if (itemToDelete) {
            const url = this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/cart-item/' + itemToDelete.id + '?rental_secret=' + this.apiSecret;
            if (typeof dataLayer != 'undefined') {
                dataLayer.push({
                    'event': 'removeFromCart',
                    'ecommerce': {
                        'remove': {                               // 'remove' actionFieldObject measures.
                            'products': [{                          //  removing a product to a shopping cart.
                                'id': itemToDelete.key,
                                'price': itemToDelete.price.inclusive.toFixed(2),
                                'quantity': itemToDelete.amount
                            }]
                        }
                    }
                });
            }

            $.ajax({
                url: url,
                type: 'DELETE'
            })
                .done((cart) => {
                    this.vueData.cart = cart;
                    this.cartLoaded();
                })
                .fail((jqXHR, textStatus, err) => {
                    this.getCart();
                })
                .always(() => {
                    this.vueData.updating = false;
                })
        } else {
            console.info('Could not remove item: item was undefined');
        }
    }

    private getConfigurations(productTwikitId, productDescriptorUrl, versionId, productData, canvasDataUrl,
                              productCreatorProductKey: string, productCreatorProductVersionTime: number,
                              twikbotVersion: string): JQueryXHR {
        return $.ajax({
            url: `${this.configurationsUrl}/api/v2/configurations`,
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
                "spaceKey": this.apiSecret,
                "productCreatorProductKey": productCreatorProductKey,
                "productCreatorProductVersionTime": productCreatorProductVersionTime,
                "twikbotVersion": twikbotVersion,
                "configurationJson": JSON.stringify({
                    parameters: productData.parameters,
                    variation_parameters: productData.variationParameters,
                    resources: productData.resources}),
                "previewImgBase64Data": canvasDataUrl,
                //"userId": "",
                "applicationId": `3d-factory-${this.apiSecret}`,
                "metadata": JSON.stringify({productVersionId: versionId})
            })
        });
    }

    public addItem(productTwikitId, productDescriptorUrl, versionId, productData, canvasDataUrl,
                   productCreatorProductKey: string, productCreatorProductVersionTime: number,
                   twikbotVersion: string) {
        this.blockUi(true);
        //First get the configuration
        this.getConfigurations(productTwikitId, productDescriptorUrl, versionId, productData, canvasDataUrl,
            productCreatorProductKey, productCreatorProductVersionTime, twikbotVersion)
        //Once you got the configuration, add that configuration to the cart
            .then((data) => {
                return $.post(this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/add?rental_secret=' + this.apiSecret,
                    {config_id: data.id})
            })
            .then((cart) => {
                this.vueData.cart = cart;
                this.cartLoaded();
                this.vueData.active = true;
                setTimeout(() => {
                    this.vueData.active = false
                }, 5000);
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                console.log('error adding to cart: ', jqXHR, textStatus, errorThrown);
            })
            .always(() => {
                this.blockUi(false);
            })
    }

    public updateItem(cartItemId: number, productTwikitId, productDescriptorUrl, versionId, productData, canvasDataUrl,
                      productCreatorProductKey: string, productCreatorProductVersionTime: number,
                      twikbotVersion: string) {
        this.blockUi(true);
        //First get the updated configuration
        this.getConfigurations(productTwikitId, productDescriptorUrl, versionId, productData, canvasDataUrl,
            productCreatorProductKey, productCreatorProductVersionTime, twikbotVersion)
            .then((data) => {
                let cartItem = null;
                if (this.vueData.cart) {
                    this.vueData.cart.items.data.forEach((value) => {
                        if (value.id == cartItemId)
                            cartItem = value;
                    });
                }

                if (cartItem) {
                    //First delete the item from the cart
                    return $.ajax({
                        url: this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/cart-item/' + cartItem.id + '?rental_secret=' + this.apiSecret,
                        type: 'DELETE'
                    })
                    //Then re-add it to the cart
                        .then(() => {
                            return $.post(this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/add?rental_secret=' + this.apiSecret,
                                {
                                    config_id: data.id,
                                    amount: cartItem.amount
                                })
                        });
                } else {
                    return $.post(this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/add?rental_secret=' + this.apiSecret,
                        {
                            config_id: data.id,
                            amount: 1
                        });
                }
            })
            .then((cart) => {
                            this.vueData.cart = cart;
                            this.cartLoaded();
                            this.vueData.active = true;
                            setTimeout(() => {
                                this.vueData.active = false
                            }, 5000);
                        })
            .fail((err) => {
                console.error(err);
            })
            .always(() => {
                this.blockUi(false);
            })
    }

    private addConfigfunction(configId: number, amount?: number) {
        this.blockUi(true);
        $.post(this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/add?rental_secret=' + this.apiSecret,
            {
                config_id: configId,
                amount: amount || 1
            })
            .then((cart) => {
                this.vueData.cart = cart;
                this.cartLoaded();
                this.vueData.active = true;
                this.blockUi(false);
                setTimeout(() => {
                    this.vueData.active = false
                }, 5000);
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                console.log('error adding to cart: ', jqXHR, textStatus, errorThrown);
                this.blockUi(false);
            });
    }

    public addConfigs(configs: { id: number, quantity: number }[], next: (err) => void) {
        this.blockUi(true);
        let url = this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/add?rental_secret=' + this.apiSecret;

        let promises = [];
        configs.forEach((config) => {
            promises.push($.ajax({
                url: url,
                method: 'post',
                data: JSON.stringify({config_id: config.id, amount: config.quantity}),
                contentType: 'application/json'
            }));
        });

        $.when.apply($, promises).then(() => {
            return $.get(this.apiUrl + '/cart/' + window.localStorage.getItem("cartId"), {rental_secret: this.apiSecret});
        })
            .then((cart) => {
                this.vueData.cart = cart;
                this.cartLoaded();
                this.vueData.active = true;
                this.blockUi(false);
                setTimeout(() => {
                    this.vueData.active = false
                }, 5000);
                return next(null);
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                console.log('error adding to cart: ', jqXHR, textStatus, errorThrown);
                return next(jqXHR);
            })
            .always(() => {
                this.blockUi(false);
            })
    };

    private updateCart(item) {
        this.vueData.updating = true;
        $.post(this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/update?rental_secret=' + this.apiSecret,
            {
                id: item.id,
                amount: item.amount
            })
            .then((cart) => {
                if (typeof cart === 'object') {
                    this.vueData.cart = cart;
                    this.cartLoaded();
                }
                else {
                    // should get cart
                    this.getCart();
                }
            })
            .fail(() => {
                this.getCart();
            })
            .always(() => {
                this.vueData.updating = false;
            })
    };

    private getCart() {
        if (window.localStorage.getItem("cartId") != null) {
            $.get(this.apiUrl + '/cart/' + window.localStorage.getItem("cartId"), {rental_secret: this.apiSecret})
                .done((cart) => {
                    this.vueData.cart = cart;
                    //Check if the cart already has a voucher
                    if(cart.voucher_code){
                        this.vueData.voucherValid = true;
                    }
                    this.cartLoaded();
                })
                .fail((jqXHR) => {
                    this.blockUi(false);
                    if (jqXHR.status == 404 || jqXHR.status == 500) {
                        window.localStorage.removeItem("cartId");
                        this.getCart();
                    }
                });
        }
        else {
            $.get(this.apiUrl + '/cart', {rental_secret: this.apiSecret})
                .then((cart) => {
                    window.localStorage.setItem("cartId", cart.id);
                    this.vueData.cart = cart;
                    this.cartLoaded();
                });
        }

    };

    private checkout() {
        const cartId = window.localStorage.getItem('cartId');
        if (cartId == null)
            return;

        this.vueData.checkoutModel.has_errors = false;
        const checkoutModel = $.extend(true, {
            cart_id: cartId,
            rental_secret: this.apiSecret,
            language: this.language
        }, this.vueData.checkoutModel);
        checkoutModel.billing_is_delivery = !checkoutModel.billingDiffersFromDelivery;

        this.blockUi(true);

        $.ajax({
            type: 'POST',
            url: this.apiUrl + '/orders',
            data: JSON.stringify(checkoutModel),
            contentType: "application/json",
            dataType: 'json'
        })
            .then((data) => {
            this.vueData.reviewModel = $.extend(true, {cart_id: cartId, rental_secret: this.apiSecret}, data);
            (<any>this.vue).setMode('review');
            setTimeout(() => {
                this.initializePaymentUI();
            }, 1);
            })
            .fail((jqXhr) => {
                this.vueData.checkoutModel.has_errors = true;
                console.log('failed to checkout cart...');
                console.log(jqXhr);
            })
            .always(() => {
                this.blockUi(false);
            })
    };

    public startCheckout() {
        (<any>this.vue).setMode('checkout');
    };

    public onCartLoaded(callback: () => void, triggerOnReloads: boolean = true, triggerIfAlreadyLoaded: boolean = true) {
        this.loadCompleteCallbacks.push(callback);
        if (triggerOnReloads) {
            this.reloadCompleteCallbacks.push(callback);
        }
        if (triggerIfAlreadyLoaded && this.vueData && this.vueData.loaded) {
            callback();
        }
    }

    public getQuantityForTwikitId(twikitId: string): number {
        let quantity = 0;
        if (this.vueData.cart) {
            this.vueData.cart.items.data.forEach((cartItem) => {
                if (cartItem.twikit_id == twikitId)
                    quantity += cartItem.amount;
            });
        }
        return quantity;
    }

    public getCartItem(cartItemId: number) {
        let cartItem = null;
        if (this.vueData.cart) {
            this.vueData.cart.items.data.forEach((value) => {
                if (value.id == cartItemId)
                    cartItem = value;
            });
        }
        return cartItem;
    }

    public getCartData() {
        return JSON.parse(JSON.stringify(this.vueData.cart));
    };

    public setCountry(countryCode: string) {
        const self = this;
        this.vueData.updating = true;
        $.post(this.apiUrl + '/cart/' + window.localStorage.getItem('cartId') + '/change-country?rental_secret=' + this.apiSecret,
            {
                country_code: countryCode
            })
            .then((cart) => {
                if (typeof cart === 'object') {
                    this.vueData.cart = cart;
                    this.cartLoaded();
                }
                else {
                    // should get cart
                    self.getCart();
                }
                this.vueData.updating = false;
            })
            .fail(() => {
                this.getCart();
                this.vueData.updating = false;
            });
    };

    public getTranslatedCurrency(currencyIso: string) {
        return cartContext.currencies[currencyIso];
    }
}